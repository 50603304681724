import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Label,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Badge,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  ModalContext,
  TempDataContext,
  FormDataContext,
  BusinessDataContext,
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";
import ResponseModal from "../../components/ResponseModal";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { businessData, businessDataDispatch } =
    useContext(BusinessDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);

  document.title = "Transfer To Customer";

  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  useEffect(() => {
    document.getElementById("receiver").addEventListener("input", function () {
      formDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "receiver", value: this.value.replace(" ", "") },
      });
    });
  }, []);

  const verifyReceiver = (e) => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "verifying receiver...", isLoading: true },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("receiver", String(formData.receiver));
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      //redirect: "follow",
    };
    fetch(localStorage.getItem("apiURL") + "verify_user", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          formDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "receiverName", value: data.data.name },
          });
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          props.history.push("/signout");
        } else {
          tempDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        tempDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "connecting to server...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("receiver", String(formData.receiver));
    urlencoded.append("description", String(formData.description));
    urlencoded.append("amount", String(formData.amount));
    urlencoded.append("password", String(formData.password));
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      //redirect: "follow",
    };
    fetch(localStorage.getItem("apiURL") + "wallet_transfer", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "responseModal",
              value: { isOpen: true, text: data.message },
            },
          });
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          props.history.push("/signout");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          tempDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
        } else {
          tempDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        tempDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        tempDataDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return (
    <React.Fragment>
      <Container fluid>
        {/* <ResponseModal/> */}
        <Row className="justify-content-center align-content-center mb-2">
          <Col xl="10" ml="10" sm="12" className=" p-0 m-0">
            <Card className="mini-stat text-dark shadow mb-2">
              <CardBody>
                <div className="mb-2">
                  <p className="text-uppercase text-left mb-0 ">
                    <b>Transfer To Customer Wallet</b>
                  </p>
                  <hr className="border-primary" />
                </div>
                {tempData.error && (
                  <Alert color="danger">{tempData.error}</Alert>
                )}

                <AvForm
                  className="form-horizontal m-t-30"
                  onValidSubmit={handleSubmit}
                >
                  <Card className="mini-stat shadow text-dark">
                    <CardBody>
                      <h6 className="text-uppercase text-dark mt-2 small">
                        Enter Receiver's Phone number or Email
                      </h6>
                      <Row>
                        <Col xs="9" className="mr-0 pr-0">
                          <AvField
                            name="receiver"
                            value={formData.receiver}
                            onChange={(e) => {
                              formOnChange(e);
                            }}
                            placeholder="Receiver"
                            type="text"
                            errorMessage="Enter an email or phone number"
                            required="true"
                            id="receiver"
                          />
                        </Col>
                        <Col xs="3">
                          <Button
                            className="waves-effect waves-light btn-primary"
                            onClick={verifyReceiver}
                          >
                            <p className="small mb-0">Verify</p>
                          </Button>
                        </Col>
                      </Row>

                      {formData.receiverName && (
                        <p className="mb-0">
                          Receiver's Name: {formData.receiverName}
                        </p>
                      )}

                      <h6 className="text-uppercase text-dark small">
                        HOW MUCH DO YOU WANT TO Transfer?
                      </h6>
                      <AvField
                        name="amount"
                        value={formData.amount}
                        onChange={(e) => {
                          formOnChange(e);
                        }}
                        placeholder="Amount"
                        type="number"
                        errorMessage="Enter valid amount"
                        validate={{
                          required: { value: true },
                          pattern: {
                            value: "^[0-9]+$",
                            errorMessage: "Only valid amount",
                          },
                        }}
                      />

                      <h6 className="text-uppercase text-dark small">
                        Description
                      </h6>

                      <AvField
                        name="description"
                        value={formData.description}
                        onChange={(e) => {
                          formOnChange(e);
                        }}
                        placeholder="Max 50 characters"
                        type="text"
                      />

                      <AvField
                        name="password"
                        value={formData.password}
                        onChange={(e) => {
                          formOnChange(e);
                        }}
                        placeholder="Enter Password"
                        type="password"
                        required
                      />
                    </CardBody>
                  </Card>
                  <Button
                    className="w-md waves-effect waves-light btn-primary"
                    type="submit"
                  >
                    Transfer{" "}
                    {formData.amount && (
                      <React.Fragment>
                        ₦{formatCurrency(formData.amount)}
                      </React.Fragment>
                    )}
                  </Button>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(Payments);

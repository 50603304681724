import React, { useContext, useEffect } from "react";
import { Alert, Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  BusinessDataContext,
  ModalContext,
  TempDataContext,
  FormDataContext,
} from "../../contexts/Contexts";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { businessData, businessDataDispatch } =
    useContext(BusinessDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);

  document.title = "Sell Airtime Plans-DataNow";

  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  useEffect(() => {
    document
      .getElementById("phone_number")
      .addEventListener("input", function () {
        formDataDispatch({
          type: "SET_FORM_DATA",
          data: { name: "phone_number", value: this.value.replace(/\D/g, "") },
        });
      });
  }, []);

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    purchaseData();
  };

  const handleFileSubmit = (e) => {
    e.persist();
    e.preventDefault();
    purchaseData();
  };

  useEffect(() => {
    if (!formData.network) {
      formDataDispatch({
        type: "SET_FORM_DATA",
        data: { name: "network", value: "MTN" },
      });
    }
  }, []);

  const purchaseData = () => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Selling airtime...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("source", String("WEB"));
    urlencoded.append("network", String(formData.network));
    urlencoded.append("amount", String(formData.amount));
    urlencoded.append("phone_number", String(formData.phone_number));
    urlencoded.append("payment_method", "ADMIN");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,

      body: urlencoded,
    };
    fetch(localStorage.getItem("apiURL") + "airtime_purchase", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: {
              name: "responseModal",
              value: { isOpen: true, text: data.message },
            },
          });
        } else if (data.message === "Token Expired") {
          props.history.push("/signout");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Row className="justify-content-center align-content-center ">
          <Col xl="10" ml="10" sm="12">
            <Card className="mini-stat text-dark shadow">
              <CardBody>
                <div className="mb-2">
                  <p className="text-uppercase text-left mb-0 ">
                    <b>Sell Airtime</b>
                  </p>
                  <hr className="border-primary" />
                </div>
                <Row>
                  <Col md="6">
                    {formData.error && (
                      <Alert color="danger">{formData.error}</Alert>
                    )}

                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={handleSubmit}
                    >
                      <p className="  text-left mb-0 ">
                        <b>Network</b>
                      </p>
                      <select
                        className="form-control mb-2"
                        value={formData.network}
                        name="network"
                        onChange={(e) => {
                          formOnChange(e);
                        }}
                      >
                        <option value=""></option>
                        <option value="MTN">MTN</option>
                        <option value="GLO">GLO</option>
                        <option value="AIRTEL">AIRTEL</option>
                        <option value="ETISALAT">ETISALAT</option>
                      </select>

                      <AvField
                        name="phone_number"
                        label="Phone Number"
                        value={formData.phone_number}
                        onChange={(e) => {
                          formOnChange(e);
                        }}
                        placeholder="Phone number"
                        type="text"
                        id="phone_number"
                        errorMessage="Enter valid phone number"
                        required
                        pattern="[0-9]{11}"
                      />

                      <AvField
                        name="amount"
                        label="Airtime Value"
                        value={formData.amount}
                        onChange={(e) => {
                          formOnChange(e);
                        }}
                        placeholder="Enter airtime amount"
                        type="number"
                        required
                        validate={{
                          pattern: {
                            value: "^[0-9]+$",
                            errorMessage: "Enter valid amount",
                          },
                        }}
                      />

                      <Button
                        className="btn-primary w-md waves-effect waves-light "
                        type="submit"
                      >
                        Sell Airtime
                      </Button>
                    </AvForm>

                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={handleFileSubmit}
                    >
                      {/* <p
                  className="  text-left mb-0 "
                >
                  <b>Select file (CSV)</b>
                     </p> 
                  <AvField
                  name="file"
                  value={formData.file}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  type="file"
                  required
                 
                />
      
     <Button
                  className="btn-primary w-md waves-effect waves-light "
                  type="submit"
                >
                  Sell All
                </Button> */}
                    </AvForm>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(Payments);

import SignIn from "./pages/Auth/SignIn";
import SignOut from "./pages/Auth/SignOut";
import SignUp from "./pages/Auth/SignUp";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";

import Dashboard from "./pages/Dashboard/dashboard";
import DataWalletTransactions from "./pages/Data/DataWalletTransactions";
import AirtimeWalletTransactions from "./pages/Airtime/AirtimeWalletTransactions";
import Customers from "./pages/Customers/Customers";
import CustomersTransactions from "./pages/Customers/CustomersTransactions";
import BusinessTransactions from "./pages/Business/BusinessTransactions";
import Withdrawals from "./pages/Business/Withdrawals";
import WithdrawFund from "./pages/Business/WithdrawFund";
import Profile from "./pages/Auth/Profile";
import Staff from "./pages/Staff/Staff";
import AddStaff from "./pages/Staff/AddStaff";
import AppSettings from "./pages/Business/AppSettings";
import BusinessProfile from "./pages/Business/BusinessProfile";
import DataPlans from "./pages/Data/DataPlans";
import AirtimePlans from "./pages/Airtime/AirtimePlans";
import DataSMEPlan from "./pages/Data/DataSMEPlan";
import SellData from "./pages/Data/SellData";
import SellAirtime from "./pages/Airtime/SellAirtime";
import TransferToCustomer from "./pages/Business/TransferToCustomer";
import BusinessRegistration from "./pages/Business/BusinessRegistration";
import CablePlans from "./pages/Cable/CablePlans";
import ElectricPlans from "./pages/Electric/ElectricPlans";
import Metadata from "./pages/Metadata/Metadata";
import HtmlSnippet from "./pages/HtmlSnippet/HtmlSnippet";
import AdImages from "./pages/AdImages/AdImages";
import MarketPlace from "./pages/MarketPlace/MarketPlace";
import FAQ from "./pages/FAQ/FAQ";
import Terms from "./pages/Auth/Terms";
import Privacy from "./pages/Auth/Privacy";
import MerchantPrivacy from "./pages/Auth/MerchantPrivacy";
import MerchantAccountDeletion from "./pages/Auth/MerchantAccountDeletion";

const routes = [
  // public Routes
  { path: "/signin", component: SignIn, ispublic: true },
  { path: "/signout", component: SignOut, ispublic: true },
  { path: "/signup", component: SignUp, ispublic: true },
  { path: "/forgotPassword", component: ForgotPassword, ispublic: true },
  { path: "/resetPassword", component: ResetPassword, ispublic: true },

  // Dashnoard
  { path: "/dashboard", component: Dashboard, withLayout: true },

  { path: "/cable-plans", component: CablePlans, withLayout: true },
  { path: "/electric-plans", component: ElectricPlans, withLayout: true },
  { path: "/sell-data", component: SellData, withLayout: true },
  {
    path: "/transfer-to-customer",
    component: TransferToCustomer,
    withLayout: true,
  },
  {
    path: "/data-wallet-subscriptions",
    component: DataWalletTransactions,
    withLayout: true,
  },
  { path: "/data-plans", component: DataPlans, withLayout: true },
  { path: "/mtn-sme-settings", component: DataSMEPlan, withLayout: true },

  {
    path: "/airtime-wallet-subscriptions",
    component: AirtimeWalletTransactions,
    withLayout: true,
  },
  { path: "/airtime-plans", component: AirtimePlans, withLayout: true },
  { path: "/sell-airtime", component: SellAirtime, withLayout: true },
  { path: "/customers", component: Customers, withLayout: true },
  {
    path: "/customers-transactions",
    component: CustomersTransactions,
    withLayout: true,
  },
  {
    path: "/customer-transactions",
    component: CustomersTransactions,
    withLayout: true,
  },
  {
    path: "/business-transactions",
    component: BusinessTransactions,
    withLayout: true,
  },
  { path: "/business-registration", component: BusinessRegistration },
  { path: "/withdrawals", component: Withdrawals, withLayout: true },
  { path: "/withdraw-fund", component: WithdrawFund, withLayout: true },
  { path: "/app-settings", component: AppSettings, withLayout: true },
  { path: "/business-profile", component: BusinessProfile, withLayout: true },
  { path: "/profile", component: Profile, withLayout: true },
  { path: "/staff", component: Staff, withLayout: true },
  { path: "/add-staff", component: AddStaff, withLayout: true },
  { path: "/transactions", component: CustomersTransactions, withLayout: true },
  { path: "/metadata", component: Metadata, withLayout: true },
  { path: "/html-snippet", component: HtmlSnippet, withLayout: true },
  { path: "/ad-images", component: AdImages, withLayout: true },
  { path: "/marketplace", component: MarketPlace, withLayout: true },
  { path: "/faq", component: FAQ, withLayout: true },
  { path: "/terms", component: Terms, ispublic: true },
  { path: "/privacy", component: Privacy, ispublic: true },
  { path: "/privacy-policy", component: MerchantPrivacy, ispublic: true },
  {
    path: "/account-deletion",
    component: MerchantAccountDeletion,
    ispublic: true,
  },
  { path: "/", component: SignIn, ispublic: true },
];

export default routes;

import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Label,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Badge,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  ModalContext,
  TempDataContext,
  BusinessDataContext,
  FormDataContext,
} from "../../contexts/Contexts";
import { formatCurrency } from "../../helpers/utils";

const Payments = (props) => {
  const { loaderDispatch } = useContext(ModalContext);
  const { tempData, tempDataDispatch } = useContext(TempDataContext);
  const { businessData, businessDataDispatch } =
    useContext(BusinessDataContext);
  const { formData, formDataDispatch } = useContext(FormDataContext);

  document.title = "Customers-DataNow";

  const formOnChange = (e) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  const navigateToPage = (page) => {
    tempDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "customers", value: false },
    });
    fetchTransactions(page);
  };

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    fetchTransactions(1);
  };

  const customerSelect = (customer) => {
    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "search", value: customer.user_id },
    });

    formDataDispatch({
      type: "SET_FORM_DATA",
      data: { name: "customer", value: customer },
    });

    props.history.push("/customer-transactions");
  };

  const Customer = (props) => {
    return (
      <React.Fragment>
        <tr>
          <td>{props.customer.email}</td>
          <td>{props.customer.phone_number}</td>
          <td>
            {props.customer.firstname} {props.customer.othername}{" "}
            {props.customer.lastname}
          </td>
          <td>₦{formatCurrency(props.customer.wallet_balance)}</td>
          <td>X2X{props.customer.reference_code}</td>
          <td>
            {JSON.parse(props.customer.auto_agent_banks).map((acct, id) => (
              <label className="small">
                {id > 0 && <label>, </label>}[
                {
                  businessData.banks[
                    businessData.banks.findIndex(
                      (bank) => bank.id * 1 === acct.bank * 1
                    )
                  ].name
                }
                {": "}
                {acct.account_name} {acct.account_number} ]
              </label>
            ))}
          </td>
          <td>{props.customer.monnify_account_number}</td>
          <td>{props.customer.sterling_account_number}</td>
          <td>{props.customer.moniepoint_account_number}</td>
          <td>{props.customer.gt_account_number}</td>
          <td>{props.customer.fidelity_account_number}</td>
          <td>{props.customer.created_at}</td>
          <td>{props.customer.referrer_id}</td>
          <td>{props.customer.user_id}</td>
          <td
            style={{ cursor: "pointer" }}
            onClick={() => {
              customerSelect(props.customer);
            }}
            className="text-primary"
          >
            View Transactions
          </td>
        </tr>
      </React.Fragment>
    );
  };

  const fetchTransactions = (page) => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Retrieving transactions...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var parameter = "";
    parameter += formData.search ? "&search=" + formData.search : "";
    parameter += formData.pageSize ? "&size=" + formData.pageSize : "";
    parameter += formData.dateFrom ? "&date_from=" + formData.dateFrom : "";
    parameter += formData.dateTo ? "&date_to=" + formData.dateTo : "";
    parameter += "&page=" + page;
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(
      localStorage.getItem("apiURL") + "customers?" + parameter,
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
        console.log(data);
        if (data.status === "success") {
          const objectValues = Object.values(data.data.data);
          console.log(objectValues);
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "customers", value: objectValues },
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "current_page", value: data.data.current_page },
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "last_page", value: data.data.last_page },
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "per_page", value: data.data.per_page },
          });
          tempDataDispatch({
            type: "SET_FORM_DATA",
            data: { name: "total", value: data.data.total },
          });
        } else if (data.message === "Token Expired") {
          props.history.push("/signout");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDataDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
        } else {
          formDataDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  const downloadData = () => {
    loaderDispatch({
      type: "SET_LOADER",
      data: { text: "Downloading...", isLoading: true },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + businessData.token);
    var parameter = "";
    parameter += formData.search ? "&search=" + formData.search : "";
    parameter += formData.pageSize ? "&size=" + formData.pageSize : "";
    parameter += formData.dateFrom ? "&date_from=" + formData.dateFrom : "";
    parameter += formData.dateTo ? "&date_to=" + formData.dateTo : "";
    parameter += formData.business_id
      ? "&business_id=" + formData.business_id
      : "";
    parameter += "&download=true";
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      localStorage.getItem("apiURL") + "customers?" + parameter,
      requestOptions
    )
      .then((response) => (response = response.blob()))
      .then((response) => {
        var url = window.URL.createObjectURL(response);
        var a = document.createElement("a");
        a.href = url;
        a.download = "customers.csv";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();

        tempDataDispatch({
          type: "SET_FORM_DATA",
          data: {
            name: "responseModal",
            value: { isOpen: true, text: "Customers Downloaded" },
          },
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      })
      .catch((error) => {
        console.log("error", error);
        formDataDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        loaderDispatch({
          type: "SET_LOADER",
          data: { text: "", isLoading: false },
        });
      });
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Row className="justify-content-center align-content-center ">
          <Col xl="12" ml="12" sm="12" className=" p-0 m-0">
            <Card className="mini-stat text-dark shadow">
              <CardBody>
                <div className="mb-2">
                  <p className="text-uppercase text-left mb-0 ">
                    <b>My Customers</b>
                    <button
                      className="btn btn-primary m-2"
                      onClick={() => {
                        downloadData();
                      }}
                    >
                      Download Selection
                    </button>
                  </p>
                  <hr className="border-primary" />
                </div>
                <AvForm
                  className="mt-2"
                  role="search"
                  onValidSubmit={handleSubmit}
                >
                  <Row>
                    <Col md="2" xs="5" className="form-group mb-2">
                      <input
                        type="date"
                        className="form-control"
                        name="dateFrom"
                        value={formData.dateFrom}
                        onChange={formOnChange}
                      />
                    </Col>
                    <label className="mt-2">To</label>
                    <Col md="2" xs="5" className="form-group mb-2">
                      <input
                        type="date"
                        className="form-control"
                        name="dateTo"
                        value={formData.dateTo}
                        onChange={formOnChange}
                      />
                    </Col>
                    <Col md="2" xs="6" className="form-group mb-2">
                      <select
                        className="form-control"
                        value={formData.pageSize}
                        name="pageSize"
                        onChange={formOnChange}
                      >
                        <option value="">15 per page</option>
                        <option value="30">30 per page</option>
                        <option value="50">50 per page</option>
                        <option value="100">100 per page</option>
                      </select>
                    </Col>

                    <div className=" mb-0 ml-3 app-search ">
                      <input
                        type="text"
                        className="form-control bg-light text-dark shadow"
                        placeholder="Search.."
                        name="search"
                        value={formData.search}
                        onChange={formOnChange}
                      />
                      <button style={{ marginTop: -12 }} type="submit">
                        <i className="mdi mdi-magnify  mdi-24px text-primary "></i>
                      </button>
                    </div>
                  </Row>
                </AvForm>

                {tempData.customers && (
                  <React.Fragment>
                    <p className="text-uppercase text-left mb-2 mt-3 small">
                      Showing (
                      {tempData.per_page * tempData.current_page -
                        tempData.per_page +
                        1}{" "}
                      to{" "}
                      {tempData.current_page * tempData.per_page -
                        tempData.per_page +
                        tempData.customers.length}{" "}
                      ) of {tempData.total} Customers
                    </p>

                    <div className="table-rep-plugin">
                      <Table responsive striped hover>
                        <thead>
                          <tr>
                            <th>Email</th>
                            <th data-priority="1">Phone No.</th>
                            <th data-priority="3">Name</th>
                            <th data-priority="3">Balance</th>
                            <th data-priority="3">Auto Agent Ref.</th>
                            <th data-priority="3">AutoAgent Banks</th>
                            <th data-priority="3">Wema</th>
                            <th data-priority="3">Sterling</th>
                            <th data-priority="3">Moniepoint</th>
                            <th data-priority="3">Gtbank</th>
                            <th data-priority="3">Fidelity</th>
                            <th data-priority="3">Reg. Date</th>
                            <th data-priority="6">Referrer ID</th>
                            <th data-priority="6">User ID</th>
                            <th data-priority="6"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {tempData.customers.map((customer) => {
                            return (
                              <Customer
                                key={customer.user_id}
                                customer={customer}
                              />
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </React.Fragment>
                )}

                {tempData.customers && (
                  <React.Fragment>
                    <hr className="mt-0" />
                    <p className="text-uppercase text-left mb-0 small">
                      Showing page {tempData.current_page} of{" "}
                      {tempData.last_page}
                    </p>
                    <Row className="ml-1 mt-2 ">
                      <Pagination className="pagination-sm">
                        <PaginationItem
                          disabled={tempData.current_page > 1 ? false : true}
                        >
                          <PaginationLink
                            first
                            onClick={() => {
                              navigateToPage(tempData.current_page - 1);
                            }}
                          />
                        </PaginationItem>
                        {tempData.current_page - 5 > 0 && (
                          <PaginationItem
                            onClick={() => {
                              navigateToPage(tempData.current_page - 5);
                            }}
                          >
                            <PaginationLink>
                              {tempData.current_page - 5}
                            </PaginationLink>
                          </PaginationItem>
                        )}

                        <PaginationItem active>
                          <PaginationLink>
                            {tempData.current_page}
                          </PaginationLink>
                        </PaginationItem>

                        {tempData.last_page >= tempData.current_page + 5 && (
                          <PaginationItem
                            onClick={() => {
                              navigateToPage(tempData.current_page + 5);
                            }}
                          >
                            <PaginationLink>
                              {tempData.current_page + 5}
                            </PaginationLink>
                          </PaginationItem>
                        )}

                        <PaginationItem
                          disabled={
                            tempData.current_page === tempData.last_page
                              ? true
                              : false
                          }
                        >
                          <PaginationLink
                            onClick={() => {
                              navigateToPage(tempData.current_page + 1);
                            }}
                            last
                          />
                        </PaginationItem>
                      </Pagination>
                    </Row>
                  </React.Fragment>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(Payments);

import React, { useState, useContext } from "react";
import {
  Alert,
  Button,
  Col,
  Row,
  Card,
  Collapse,
  CardBody,
  CardHeader,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import logoBg from "../../images/logo-dark.png";
import logo from "../../images/logo-sm.png";
import wave from "../../images/wave.svg";
import ResponseModal from "../../components/ResponseModal";

import {
  ModalContext,
  TempDataContext,
  BusinessDataContext,
} from "../../contexts/Contexts";

const SignIn = (props) => {
  const { businessData, businessDataDispatch } =
    useContext(BusinessDataContext);

  const segments = new URL(window.location).pathname.split("/");
  const last = segments.pop() || segments.pop(); // Handle potential trailing slash
  if (last === "privacy-policy") props.history.push("/");

  document.title = last + "-Privacy Policy";

  businessData.merchant && props.history.push("/dashboard");

  return (
    <React.Fragment>
      <div className="text-center  pt-4">
        <Link to="">
          {" "}
          <img
            src={logoBg}
            alt=""
            height="36"
            className="float-left mb-3 mr-3 ml-3"
          />
        </Link>
      </div>
      <h1 className="m-3 text-center text-dark">
        {last} Account Deletion Process{" "}
      </h1>

      <Row className="justify-content-center">
        <Col xs="12" md="8">
          <p>
            {last} is committed to managing your Personal Information in line
            with global industry best practices. Kindly follow the following
            steps to delete your account securely
          </p>

          <h4>Data Saved with Us</h4>
          <p>
            The following data or information are the ones kept with us due to
            you opening an account
          </p>
          <p>Full Name</p>
          <p>Phone Number</p>
          <p>Email Address</p>
          <p>Encrypted Account Password</p>

          <p>
            To securely delete your account with us, kindly send an email to
            support@systemstech.com.ng with your email subject as "Request for
            Account Deletion" using your registered email address. Your details
            will be deleted within 90days
          </p>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <Row
        className="justify-content-center bg-primary text-white pt-3"
        id="contact"
      >
        <Col xs="12">
          <p style={{ fontSize: 12 }} className="text-center text-white">
            <Link className="text-white ml-2 mr-2" to="terms">
              Terms and Conditions
            </Link>
            <Link className="text-white ml-2 mr-2" to="privacy">
              Privacy Policy
            </Link>
          </p>
        </Col>
        <Col xs="12">
          <p style={{ fontSize: 16 }} className="text-center pb-4">
            ©{new Date().getFullYear()} Systems Technologies, All rights
            reserved.
          </p>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withRouter(SignIn);
